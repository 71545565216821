<template>
  <div class="video-list-page">
    <HeaderMb :path="false"></HeaderMb>
    <Header></Header>

    <el-main class="content page-main">
      <div class="tag-box">全部短剧</div>

      <div class="list" ref="getListStyle">
        <div
          class="video-item"
          @click="onRoute(item)"
          v-for="item in videoList"
          :key="item.videoId"
        >
          <img
            v-if="item.isMade === 1"
            class="video-logo header-xs-box hidden-sm-and-up"
            src="../assets/img/mb/video-logo.png"
          />
          <img
            v-if="item.isMade === 1"
            class="video-logo hidden-xs-only"
            src="../assets/img/logo-pc-2.png"
          />
          <div class="video-book" :style="{ height: itemStyleHeight }">
            <img
              :style="{ height: itemStyleHeight }"
              v-if="item.videoImage"
              class=""
              :src="item.videoImage"
            />
            <img
              :style="{ height: itemStyleHeight }"
              v-else
              class=""
              src="../assets/img/default.png"
            />
            <p class="video-number">{{ item.videoTotal }}集连载中</p>
          </div>
          <p class="video-name">{{ item.videoName }}</p>
        </div>
        <div class="zw"></div>
      </div>
    </el-main>
    <Footer :position="position"></Footer>
  </div>
</template>
<script>
import Footer from "../../src/components/Footer";
import Header from "../../src/components/Header";
import HeaderMb from "../../src/components/HeaderMb";
export default {
  components: {
    Footer,
    Header,
    HeaderMb
  },
  data() {
    return {
      bannerStyle: {
        w: "100%",
        h: "195px"
      },
      picWordList: [],
      pageNum: 1,
      pageSize: 15,
      total: 1,
      videoList: [],
      itemStyleHeight: "auto",
      position: false
    };
  },
  mounted() {
    if (
      this.$refs.getListStyle.offsetWidth &&
      document.body.clientWidth > 768
    ) {
      let widthBox = this.$refs.getListStyle.offsetWidth;
      let itemStyleHeight = ((widthBox * 0.2 - 16) * 1.43).toFixed(2);
      this.itemStyleHeight = itemStyleHeight + "px";
    } else {
      let widthBox = this.$refs.getListStyle.offsetWidth;
      let itemStyleHeight = ((widthBox * 0.3333 - 7) * 1.43).toFixed(2);
      this.itemStyleHeight = itemStyleHeight + "px";
    }

    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          let winW = document.body.clientWidth;
          if (this.$refs.getListStyle.offsetWidth && winW > 768) {
            let widthBox = this.$refs.getListStyle.offsetWidth;
            let itemStyleHeight = ((widthBox * 0.2 - 16) * 1.43).toFixed(2);
            this.itemStyleHeight = itemStyleHeight + "px";
          } else {
            let widthBox = this.$refs.getListStyle.offsetWidth;
            let itemStyleHeight = ((widthBox * 0.3333 - 7) * 1.43).toFixed(2);
            this.itemStyleHeight = itemStyleHeight + "px";
          }
        });
      })();
    };

    this.getPageVideo();
    let bannerWidth = document.documentElement.clientWidth - 28;
    this.bannerStyle = {
      w: bannerWidth + "px",
      h: bannerWidth / 1.8 + "px"
    };

    this.$nextTick(() => {
      // 监听滚动事件
      //类名为guest-info-box的div必须设置高度和 overflow-y: scroll;下面的代码才会生效
      let scrollTargetBox = document.querySelector(".video-list-page");
      scrollTargetBox.onscroll = () => {
        var scrollHeight = scrollTargetBox.scrollHeight; //251
        var scrollTop = scrollTargetBox.scrollTop; //0-18
        var clientHeight = scrollTargetBox.clientHeight; //233
        if (scrollHeight - clientHeight == scrollTop) {
          //滚动条滚到最底部
          console.log("滚动条滚到最底部---");

          if (this.videoList.length >= this.total) return;
          this.pageNum++;

          this.getPageVideo();
        }
      };
    });
  },
  destroy() {},

  methods: {
    // 分页获取列表
    getPageVideo() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        orderByColumn: "video_weight",
        isAsc: "desc",
        enable: 1
      };
      this.$api.getHomeVideoList(data).then(res => {
        let temp = res.rows || [];
        if (this.pageNum > 1) {
          this.videoList = [...this.videoList, ...temp];
        } else {
          this.videoList = temp;
        }
        this.position = res?.total > 6 ? true : false;
        this.total = res.total;
      });
    },
    onRoute(item) {
      this.$router.push({
        path: "/play",
        query: {
          videoId: item.videoId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/videoList.scss";
@import "../assets/styles/mediaVideoList.scss";
</style>
